export const environment = {
  name: 'production',
  production: true,
  minLengthShortText: 3,
  maxLengthShortText: 10,
  minLengthLongText: 5,
  maxLengthLongText: 15,
  recaptchaSiteKey: 'RECAPTCHA',
  PUBLIC_API: 'https://app-user.applyze.com',
  API_KEY: 'xXspnfUxPzOGKNu90bFAjlOTnMLpN8veiixvEFXUw9I=',
  SHOPIROLLER_API: 'https://api.shopiroller.com',
  version: 'v1.0.0'
};
